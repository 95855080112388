import ReactTooltip from "react-tooltip";
import useSound from "use-sound";

const CardSosial = ({ title, tooltip, href, img, id }) => {
  const soundUrl = '/sound/hover.mp3';
  const [play] = useSound(
    soundUrl,
    { volume: 0.8 }
  );
  return (
    <>
      <div className="flex justify-center ease-in-out duration-300 hover:scale-125 duration-500 hover:text-white">
        <a
          href={href}
          className="h-full w-full"
          rel="noreferrer"
          target="_blank"
          data-tip
          data-for={id}
          onClick={play}
        >
          <img
            className="h-9 mb-1 mx-6"
            src={img}
            alt={title}
          />
        </a>
      </div>
      <ReactTooltip id={id} type="dark" place="bottom" effect="solid">
        <span className="text-xs font-bold">{tooltip}</span>
      </ReactTooltip>
    </>
  );
};

export default CardSosial;