import ReactTooltip from "react-tooltip";
import useSound from "use-sound";

const CardPortal = ({ title, tooltip, href, img, id }) => {
  const soundUrl = '/sound/hover.mp3';
  const [play] = useSound(
    soundUrl,
    { volume: 0.8 }
  );
  return (
    <>
      <div className="flex flex-col bg-white rounded shadow-xl w-full my-2 mx-10 sm:mx-2 overflow-hidden sm:w-36 sm:h-32 text-gray-600 ease-in-out duration-300 hover:scale-110 hover:bg-jingga-100 duration-500 hover:text-white">
        <a
          href={href}
          className="h-full w-full"
          rel="noreferrer"
          target="_blank"
          data-tip
          data-for={id}
          onClick={play}
        >
          <img
            className="h-12 my-4 mx-auto"
            src={img}
            alt={title}
          />
          <h5 className="mb-2 text-sm text-center font-semibold tracking-tight">
            {title}
          </h5>
        </a>
      </div>
      <ReactTooltip id={id} type="dark" place="bottom" effect="solid" offset={{bottom: 10}}>
        <span className="text-xs font-bold">{tooltip}</span>
      </ReactTooltip>
    </>
  );
};

export default CardPortal;